<template>
  <nav>
    <ion-header :class="{'menu-open': menuIsOpen}">
      <ion-toolbar>
        <ion-title v-html="getHeadline()"></ion-title>
        <ion-icon 
            class="menu-toggle" 
            :icon="getIcon()" 
            @click="toggleMenu"
        />
        <ion-icon 
            class="menu-search" 
            :icon="search" 
            @click="toggleSearch"
        />
      </ion-toolbar>
    </ion-header>
    <div class="search" :class="{'open': searchOpen}">
      <form @submit.prevent="submitSearch" class="inline-block">
        <div class="search-input-wrapper">
          <ion-icon 
            class="search-input-overlay" 
            :icon="search"
          />
          <input type="search" name="q" v-model="query" ref="queryInput" placeholder="Schlagwort eingeben.." required minlength="3">
          <ion-icon 
            @click="clearSearch"
            class="clear-btn" 
            :icon="close"
          />
        </div>
      </form>
      <a @click="toggleSearch" class="close-search">Schließen</a>
    </div>
    <div class="menu" :class="{'open': menuIsOpen}">
      <ul>
        <li v-for="(page, index) in pages" :key="index">
          <router-link :to="`/pages/${page.id}`" class="font-face--merrisans">{{ page.header }}</router-link>
        </li>
        <template v-if="pagesLoaded && !pages.length">
          <li>
            <router-link to="/legal/legal-information" class="font-face--merrisans">Impressum</router-link>
          </li>
          <li>
            <router-link to="/legal/privacy-policy" class="font-face--merrisans">Datenschutzerklärung</router-link>
          </li>
        </template>
      </ul>
      <a 
        href="#" 
        role="button"
        class="logout btn btn-next btn-secondary"
        v-if="$route.path !== '/profile'"
        @click.prevent="goToLoginOrProfile"
      >
        <span>{{ loginButtonText }}</span>
      </a>
    </div>
  </nav>
</template>

<script>
import { IonHeader, IonToolbar, IonTitle, IonIcon } from '@ionic/vue';
import { menu, close, search } from 'ionicons/icons'

// @ts-ignore
import * as api from '@/service/index.js'

export default {
  name: 'Header',
  components: { 
    IonHeader, 
    IonToolbar, 
    IonTitle,
    IonIcon
  },
  props: {
    name: String
  },
  setup() {
    return {
      menu,
      close,
      search
    }
  },
  data () {
    return {
      menuIsOpen: false,
      searchOpen: false,
      query: '',
      pages: [],
      pagesLoaded: false
    }
  },
  computed: {
    isAuthenticated () {
      return localStorage.getItem('jwt') !== null
    },
    loginButtonText () {
      return this.isAuthenticated ? 'Benutzerkonto' : 'Mitarbeiter-Login'
    }
  },
  methods: {
    getHeadline () {
      return this.name === "UKK Aktuell" ? `UKK <span class="font-face--brand">Aktuell</span>` : `<span class="font-face--brand">${this.name}</span>`
    },
    getIcon () {
      return !this.menuIsOpen ? this.menu : this.close
    },
    closeMenuAndSearch () {
      console.log('close')
      this.menuIsOpen = false
      this.searchOpen = false
      this.query = ''
    },
    toggleMenu () {
      if (!this.menuIsOpen) {
        this.searchOpen = false
        this.query = ''

        if (!document.body.classList.contains('backdrop-active')) {
          document.body.classList.add('backdrop-active')
        }
        if (!document.body.classList.contains('menu-open')) {
          document.body.classList.add('menu-open')
        }

        this.menuIsOpen = true

        // close menu on backdrop click
        document.body.addEventListener('click', this.backdropHandler, false)
      } else {
        this.menuIsOpen = false
        this.removeBackdropAndClickHandler()
      }
    },
    toggleSearch () {
      if (!this.searchOpen) {
        this.menuIsOpen = false
        if (document.body.classList.contains('menu-open')) {
          document.body.classList.remove('menu-open')
        }

        if (!document.body.classList.contains('backdrop-active')) {
          document.body.classList.add('backdrop-active')
        }

        this.searchOpen = true

        setTimeout(() => {
            this.$refs.queryInput.focus()
        }, 500)

        // close search on backdrop click
        document.body.addEventListener('click', this.backdropHandler, false)
      } else {
        if (this.$route.name === 'search') {
          this.$router.push('/')
        }
        else {
          this.searchOpen = false
          this.query = ''
          this.removeBackdropAndClickHandler()
        }
      }
    },
    submitSearch () {
      location.href = `/search/${this.query}`
      // this.$router.push({ name: 'search', params: { query: this.query } })
    },
    clearSearch () {
      this.query = ''
      setTimeout(() => {
          this.$refs.queryInput.focus()
      }, 500)
    },
    backdropHandler (evt) {
      console.log(evt)
      if (evt.target.classList.contains('backdrop-active')) {
          this.closeMenuAndSearch()
          document.body.classList.remove('backdrop-active')
          document.body.classList.remove('menu-open')
          document.querySelector('.search').classList.remove('open')
          document.querySelector('.menu').classList.remove('open')
          document.body.removeEventListener('click', this.backdropHandler, false)
      }
    },
    removeBackdropAndClickHandler () {
      if (document.body.classList.contains('backdrop-active')) {
          document.body.classList.remove('backdrop-active')
          document.body.classList.remove('menu-open')
          this.closeMenuAndSearch()
      }
      document.body.removeEventListener('click', this.backdropHandler, false)
    },
    goToLoginOrProfile () {
      location.href = this.isAuthenticated ? '/profile' : '/auth/login';
    }
  },
  watch: {
    $route() {
      // react to route changes...
      this.menuIsOpen = false
      this.searchOpen = false
      this.query = ''
    }
  },
  mounted () {
    if (this.$route.name === 'search' && this.$route.params.query) {
      this.query = this.$route.params.query
      this.searchOpen = true
    }

    api.getContentElements()
      .then((result) => {
        if (result.status === 200 && result.data?.contentElements.length) {
          this.pages = result.data.contentElements
          this.pagesLoaded = true
        } 
      })
      .catch((error) => {
        console.log(error)
        this.pagesLoaded = true
      })
  }
}
</script>

<style scoped lang="less">
nav {
    position: relative;
}

ion-title {
    padding: 0 15px;
    font-size: 28px;
}

ion-toolbar {
    position: relative;
}

.menu-search {
    color: #fff;
    position: absolute;
    right: 15px;
    top: 9px;
    width: 22px;
    height: 22px;
    background: var(--text-color-blue);
    border-radius: 50%;
    padding: 8px;
}

.menu-toggle {
    color: var(--text-color-blue);
    position: absolute;
    right: 68px;
    top: 9px;
    width: 38px;
    height: 38px;
}

.logout {
  width: 220px;
  margin-left: calc(50% - 110px);
}

.close-search {
  display: inline-block;
  width: 75px;
  margin-left: 15px;
  color: var(--text-color-blue);
}

.inline-block {
  display: inline-block;
  width: calc(100% - 100px);
}

.search {
  position: absolute;
  width: 100%;
  height: 56px;
  top: env(safe-area-inset-top);
  left: -100vw;
  background: #fff;
  transition: left .3s;
  z-index: 10;

  &.open {
    left: 0;
  }

  input {
    background: rgb(236, 236, 237);
    border: none;
    border-radius: 18px;
    color: var(--text-color-grey);
    outline: none;
    width: calc(100% - 15px);
    padding: 0 13px 0 38px;
    font-size: 16px;
    min-height: 36px;
    line-height: 36px;
    margin: 10px 0 10px 15px; 
  }
}

.search-input-wrapper {
  position: relative;

  .clear-btn {
    position: absolute;
    right: 13px;
    font-size: 21px;
    line-height: 21px;
    color: #919191;
    cursor: pointer;
    display: inline-block;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
  }

  .search-input-overlay {
    position: absolute;
    left: 27px;
    display: inline-block;
    z-index: 2;
    top: 50%;
    transform: translateY(-50%);
    color: #919191;
  }
}

.menu {
    position: absolute;
    width: 100%;
    min-height: 245px;
    top: 0;
    transform: translateY(-1000px);
    left: 0;
    background: #fff;
    transition: transform .3s ease-out;
    z-index: 5;
    padding-top: 0;
    border-top: 1px dashed #CCC;

    &.open {
        // top: 56px; // height of the toolbar
        transform: translateY(calc(env(safe-area-inset-top) + 56px));
    }

    &:after {
        left: 0;
        bottom: -5px;
        background-position: left 0 top -2px;
        position: absolute;
        width: 100%;
        height: 5px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAHBAMAAADzDtBxAAAAD1BMVEUAAAAAAAAAAAAAAAAAAABPDueNAAAABXRSTlMUCS0gBIh/TXEAAAAaSURBVAjXYxCEAgY4UIICBmMogMsgFLtAAQCNSwXZKOdPxgAAAABJRU5ErkJggg==);
        background-repeat: repeat-x;
        content: "";
    }

    ul {
      margin: 0;
      margin-block-start: 0;
      padding-inline-start: 0;
      margin-bottom: 25px;
      border-bottom: 1px dashed #CCC;
      padding: 15px 0;

      li {
        padding: 15px;
        font-size: 20px;
        
        a {
          color: var(--text-color-blue);
          text-decoration: none;
          font-weight: 500;
        }
      }
    }
}

@media (prefers-color-scheme: dark) {
  ion-title {
      color: #fff;
  }

  .search {
    background: #404040;
  }

  .menu {
    background: #24272C;
    border-color: #404040;

    ul {
      border-color: #404040;
    }

    .btn.btn-secondary {
      color: #fff;
      background: var(--text-color-blue);

      &.btn-next span::after {
        background-image: url('~@/assets/img/icons/arrow-next.svg');
      }
    }
  }
}
</style>