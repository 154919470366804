<template>
  <ion-page>
    <Header name="Impressum" />
    <ion-content :fullscreen="true">
      <div id="container">
        <ion-grid style="padding:0">
          <ion-row>
            <ion-col style="padding:0">
              
              <div class="card">
                <p><strong>Universitätsklinikum Köln (AöR)</strong></p> 
                <p> </p> 
                <p>Prof. Dr. Edgar Schömig, Vorstandsvorsitzender und Ärztlicher Direktor<br> Damian Grüttner, Stellv. Vorstandsvorsitzender und Kaufmännischer Direktor</p> 
                <p>Kerpener Str. 62, 50937 Köln<br> Telefon +49 221 478-0<br> Telefax +49 221 478-4095<br> E-Mail <a href="mailto:info@uk-koeln.de" class="mail">info<span>@</span>uk-koeln.de</a><br> Bewerbungen bitte an <a href="mailto:bewerbungsmanagement@uk-koeln.de" title="E-Mail schreiben" class="mail">bewerbungsmanagement<span>@</span>uk-koeln.de</a><br> Patientenanfragen an <a href="mailto:info@uk-koeln.de" class="mail">info<span>@</span>uk-koeln.de</a> und<br> Rechnungsangelegenheiten an <a href="mailto:zentrale-rechnungsstelle@uk-koeln.de" title="Mail senden" class="mail">zentrale-rechnungsstelle<span>@</span>uk-koeln.de</a></p> 
                <p>Das Universitätsklinikum Köln ist eine Anstalt des öffentlichen Rechts.</p> 
                <p><br> <strong>Zuständige Rechtsaufsichtsbehörde (§ 14 Verordnung über die Errichtung des Klinikums Köln):</strong></p> 
                <p>Ministerium für Kultur und Wissenschaft des Landes Nordrhein-Westfalen, 40190 Düsseldorf</p> 
                <p><strong>Umsatzsteuer-Identifikationsnummer gem. §27 a Umsatzsteuergesetz:</strong></p> 
                <p>DE 215 420 431</p> 
                <p><strong>Beauftragter für Medizinproduktesicherheit:</strong></p> 
                <p><a href="mailto:medizinproduktesicherheit@uk-koeln.de" title="Mail schreiben" class="mail">medizinproduktesicherheit<span>@</span>uk-koeln.de</a></p> 
                <p><strong>Datenschutzbeauftragter:</strong></p> 
                <p><a href="mailto:datenschutz@uk-koeln.de" title="Mail schreiben" class="mail">datenschutz<span>@</span>uk-koeln.de</a></p> 
                <p><br> <strong>Inhaltlich verantwortlich:</strong></p> 
                <p>Timo Mügge, Leiter Stabsabteilung Unternehmenskommunikation und Marketing<br> Kerpener Str. 62, 50937 Köln</p> 
                <p> </p> 
                <p>Zum Web-Angebot</p> 
                <p>Für die Richtigkeit, Aktualität, Vollständigkeit und Qualität der bereitgestellten Informationen übernimmt das Universitätsklinikum Köln keine Gewähr. Das Angebot kann auch jederzeit ohne gesonderte Ankündigung teilweise oder ganz verändert oder gelöscht werden. Haftungsansprüche wegen ideeller oder materieller Schäden durch den Gebrauch der bereitgestellten Informationen sind ausgeschlossen, solange seitens des Universitätsklinikums Köln kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.</p> 
                <p>Zu Verweisen und Links</p> 
                <p>Das Universitätsklinikum Köln übernimmt für eigene und fremde Inhalte auf den Internetseiten keinerlei Haftung. Das Universitätsklinikum Köln und die Autoren der bereitgestellten Informationen haben keinen Einfluss auf die aktuelle und zukünftige Gestaltung und die Inhalte der verlinkten Seiten und distanzieren sich von allen Inhalten, die nach der Verlinkung verändert wurden. Für Schäden, die durch die Nutzung, bzw. Nichtnutzung dieser Informationen entstehen, haften ausschließlich die Anbieter dieser Seiten. Diese Haftungseinschränkung gilt für alle Verweise und Links im Webangebot des Klinikums sowie für alle Einträge in Diskussionsforen, Mailinglisten, elektronischen Gästebüchern usw. Rechtswirksamkeit dieses Haftungsausschlusses</p> 
                <p>Dieser Haftungsausschluss ist als Teil des Internetangebots anzusehen, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokuments davon unberührt.</p> 
                <p>Verbraucherstreitbeilegungsgesetz (VSBG)</p> 
                <p>Das Universitätsklinikum Köln weist gemäß § 36 Abs. 1 Verbraucherstreitbeilegungsgesetz (VSBG) darauf hin, dass wir weder verpflichtet noch bereit sind, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p> 
                <p>Copyright</p> 
                <p>Copyright © Universitätsklinikum Köln AöR<br> Sämtliche Inhalte dieser Website, insbesondere Texte, Bilder, Grafiken, Sounds, Videos, Animationen und Layout, sind urheberrechtlich geschützt. Unerlaubte Verwendung, Reproduktion oder Weitergabe einzelner Inhalte, insbesondere des Logos, oder kompletter Seiten sind ausdrücklich untersagt.</p> 
                <p>Die Internet-Seiten des Universitätsklinikums Köln wurden mit viel Sorgfalt entwickelt. Trotzdem kann es immer einmal passieren, dass sich ein Fehler einschleicht. Wir würden uns freuen, wenn Sie uns gegebenenfalls darauf aufmerksam machen könnten.</p> 
                <p><router-link to="/legal/privacy-policy">Datenschutzerklärung</router-link></p> 
                <p><a href="https://www.uk-koeln.de/impressum/bildnachweise/">Zu den Bildnachweisen</a></p> 
                <p> </p> 
              </div>

            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent, IonCol, IonGrid, IonRow } from '@ionic/vue';
import Header from '@/components/Header.vue';

export default  {
  name: 'LegalInformation',
  components: { 
    Header, 
    IonContent, 
    IonPage, 
    IonCol, 
    IonGrid, 
    IonRow 
  },
  data () {
    return {

    }
  },
  methods: {

  }
}
</script>

<style scoped lang="less">
h1 {
  font-size: 28px;
  line-height: 37.7px;
  color: var(--text-color-blue);
  margin-top: 0;
}
p {
  font-size: 15px;
  line-height: 28px;
  color: var(--text-color-grey);
}

@media (prefers-color-scheme: dark) {
  p {
    color: #fff;
  }
}
body.dark {
  p {
    color: #fff;
  }
}
</style>