
import { IonPage, IonContent, IonCol, IonGrid, IonRow } from '@ionic/vue';
import Header from '@/components/Header.vue';

export default  {
  name: 'LegalInformation',
  components: { 
    Header, 
    IonContent, 
    IonPage, 
    IonCol, 
    IonGrid, 
    IonRow 
  },
  data () {
    return {

    }
  },
  methods: {

  }
}
